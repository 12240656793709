<template>
  <div class="PlaceOrder">
    <van-sticky style="width: 100%">
      <van-nav-bar
        :title="`${locationType === 'Parking' ? 'TICKET' : 'ORDER'} HISTORY`"
        left-arrow
        @click-left="onClickLeft"
      >
        <template #left>
          <van-icon name="arrow-left" color="#ffffff" size="16pt" />
          <span style="color: #fff; font-size: 12pt">MENU</span>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="content">
      <template v-for="(item, index) in order">
        <div class="order" :key="index" @click="goOrderDetail(item.id)">
          <span
            class="text word-break"
            style="font-weight: bold; font-size: 13pt"
          >
            {{ filterText("Order", item.roomNum) }} #:&nbsp;{{ item.orderId }}
          </span>
          <span class="text word-break">
            {{ filterText("Meal", item.roomNum) }} Type: {{ item.mealPeriod }}
          </span>
          <template v-if="item.chargeRoom">
            <span class="text word-break"> Room #: {{ item.chargeRoom }} </span>
            <span class="text word-break"> Table #: {{ item.roomNum }} </span>
          </template>
          <span v-else class="text word-break">
            Room #: {{ item.roomNum }}
          </span>
          <span class="text word-break">
            {{ filterText("Order", item.roomNum) }} Time:&nbsp;Today
            {{ moment(item.orderTime).format("h:mm A") }}
          </span>
          <span class="text word-break" v-if="item.roomNum !== 'Parking'">
            Requested Time:&nbsp;{{ item.time }}
          </span>
          <span class="text word-break" v-if="item.roomNum === 'Parking'">
            From:&nbsp;{{
              item.shoppingFood[0].fromDate +
              " " +
              item.shoppingFood[0].fromTime
            }}
          </span>
          <span class="text word-break" v-if="item.roomNum === 'Parking'">
            To:&nbsp;{{
              item.shoppingFood[0].toDate + " " + item.shoppingFood[0].toTime
            }}
          </span>
          <span
            class="text word-break"
            style="font-weight: bold; font-size: 13pt"
          >
            TOTAL:&nbsp;&nbsp;${{ item.total.toFixed(2) }}
          </span>
          <van-icon class="icon" name="arrow" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import moment from "moment-timezone";

export default {
  name: "Order",
  components: {},
  data() {
    return {
      url: {
        getFee: "/adminApi/foodOrder/getFeeList",
      },
      feeList: [],
      orderId: "202303280001",
      locationType: "",
    };
  },
  computed: {
    ...mapGetters({
      order: "order", //购物车中的餐品
    }),
  },
  created() {
    this.locationType = this.$route.query.location;
    if (this.order.length == 0) {
      let query = {
        hotelname: localStorage.getItem("hotelName"),
      };
      if (this.$route.query.room) {
        query.room = this.$route.query.room;
      }
      if (this.$route.query.location) {
        query.location = this.$route.query.location;
      }
      this.$router.push({
        path: "/",
        query: query,
      });
    }
    // console.log(this.order);
  },
  methods: {
    moment,
    filterText(text, roomNum) {
      if (roomNum === "Parking") {
        return "Ticket";
      }
      return text;
    },
    goOrderDetail(id) {
      this.$router.push({
        path: "/orderDetail",
        query: { id: id, location: this.locationType },
      });
    },
    getSize(item) {
      if (item.isFull) {
        if (item.fullName) {
          return "(" + item.fullName + ")";
        } else {
          return "";
        }
      } else {
        if (item.halfName) {
          return "(" + item.halfName + ")";
        } else {
          return "";
        }
      }
    },
    onClickLeft() {
      let query = {
        hotelname: localStorage.getItem("hotelName"),
      };
      if (this.$route.query.room) {
        query.room = this.$route.query.room;
      }
      if (this.$route.query.location) {
        query.location = this.$route.query.location;
      }
      this.$router.push({
        path: "/",
        query: query,
      });
    },
    isShow(food, id) {
      for (let i = 0; i < food.foods.length; i++) {
        const element = food.foods[i];
        if (element.checked.indexOf(id) != -1) {
          return true;
        }
        // if (element.type == "Radio") {
        //   if (element.checked == id) {
        //     return true;
        //   }
        // } else {
        //   if (element.checked.indexOf(id) != -1) {
        //     return true;
        //   }
        // }
      }

      if (id == food.drinksChildrenChecked) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.PlaceOrder {
  .word-break {
    word-break: normal;
  }

  height: 100%;
  background: #f5f4f4;
  display: flex;
  flex-flow: column;
  align-items: center;

  /deep/ .van-nav-bar {
    background-color: $primary-color;
    height: 35pt;
    width: 100%;

    .van-nav-bar__title {
      color: #fff;
      font-size: 14pt;
    }
  }

  /deep/ .van-grid-item__content {
    background: #f5f4f4;
    padding: 17pt 3.75pt 7.5pt;
  }

  /deep/ .van-nav-bar__content {
    height: 100%;
  }

  .content {
    width: calc(100% - 12pt);
    height: calc(100% - 35pt);
    padding: 10pt;
    overflow: auto;

    /deep/ .van-badge__wrapper {
      width: 100%;
      margin-top: 10pt;
    }

    .food {
      width: 100%;
      text-align: left;
      background: #f9f9f9;
      border-radius: 7pt;

      .food-name {
        background-color: #ffffff;
        padding: 5pt 10pt;
        border-bottom: 1pt #dcdcdc solid;
        border-radius: 7pt 7pt 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 15pt;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #2b2e36;
        }

        .price {
          font-size: 12pt;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #636464;
          margin-left: 10pt;
        }
      }

      .item {
        background-color: #ffffff;
        padding: 0pt 10pt 5pt;
        margin-bottom: 2pt;

        .item-name {
          span {
            font-size: 12pt;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: #636464;
          }
        }

        .radio-group {
          /deep/ .van-radio__label {
            font-size: 12pt;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2b2e36;
            line-height: 20pt;
            margin-left: 10pt;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-right: 30pt;
          }

          /deep/ .van-radio__icon--checked .van-icon {
            background-color: #686868;
            border-color: #686868;
          }
        }

        .select {
          display: flex;
          align-items: center;
          margin-bottom: 5pt;

          .type {
            width: 50pt;
            height: 20pt;
            background: #ffffff;
            border-radius: 3.5pt;
            border: 1pt solid #979797;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10pt;

            span {
              font-size: 12pt;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2b2e36;
            }
          }

          .type-checked {
            // background: #f7f7f7;
            background: rgba(0, 0, 0, 0.7);
            border: 1pt solid #333333;
            width: 50pt;
            height: 20pt;
            border-radius: 3.5pt;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10pt;

            span {
              font-size: 12pt;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              //   color: #2b2e36;
              color: #ffffff;
            }
          }
        }
      }

      .border-radius {
        border-radius: 0 0 7pt 7pt;
      }
    }

    .fee {
      font-size: 12pt;
      display: flex;
      padding: 5pt 10pt;
      text-align: left;
    }

    .order {
      background-color: #ffffff;
      border-radius: 7pt;
      display: flex;
      flex-flow: column;
      position: relative;
      text-align: left;
      margin-top: 10pt;
      padding: 10pt;

      .text {
        font-size: 12pt;
        font-family: PingFangSC-Semibold, PingFang SC;
      }

      .icon {
        font-size: 22pt;
        position: absolute;
        right: 3%;
        top: 35%;
        color: #636464;
      }
    }
  }
}
</style>
